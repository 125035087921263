import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import ServerDown from 'images/server-down.svg';

class ErrorBoundary extends Component {
  state = { hasError: false, error: null, eventId: null };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <main>
          <section className="section">
            <div className="container is-fullhd is-fluid">
              <div className="box has-text-centered">
                <h1 className="title is-1 is-size-3-mobile">Une erreur est survenue!</h1>
                <p className="subtitle is-size-6-mobile">
                  Nos équipes font le nécessaire pour corriger le problème, veuillez nous excuser
                  pour le désagrément.
                </p>
                <div>
                  <a href={document.referrer || '/'} className="button is-link">
                    Retour
                  </a>
                </div>
                <img src={ServerDown} alt="Something went wrong" width="70%" />
              </div>
            </div>
          </section>
        </main>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
