import gql from 'graphql-tag';

export const MissionFragment = gql`
  fragment MissionFragment on Mission {
    code
    contractNumber
    distance
    duration
    id
    slotStart
    booking {
      id
      paidAt
      paidWith
      referenceBooking {
        id
      }
      customer {
        id
        firstname
        lastname
        phone
      }
      store {
        id
        name
        company {
          slug
        }
      }
    }
    destination {
      id
      city
      postalCode
    }
    origin {
      id
      city
      postalCode
    }
    vehicleCategory {
      displayName
    }
    options {
      quantity
      option {
        name
        slug
      }
    }
    run {
      state
      driver {
        id
        firstname
        lastname
        phone
        vehicleCategory {
          displayName
        }
      }
    }
  }
`;

export const mutationMissionAbort = gql`
  mutation MissionAborted(
    $missionId: String!
    $abortedType: AbortedTypeEnum!
    $abortedFor: String!
  ) {
    missionAborted(missionId: $missionId, abortedType: $abortedType, abortedFor: $abortedFor) {
      id
      run {
        state
      }
    }
  }
`;

export const mutationMissionRestore = gql`
  mutation MissionRestore($missionId: String!) {
    missionRestoreFromCancellation(missionId: $missionId) {
      id
      run {
        state
      }
    }
  }
`;

export const mutationMissionReset = gql`
  mutation MissionReset($missionId: String!) {
    missionResetProgress(missionId: $missionId) {
      id
      run {
        state
      }
    }
  }
`;
