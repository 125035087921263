import gql from 'graphql-tag';

export const mutationOVHAgentConnect = gql`
  mutation ovhAgentConnect($number: String!, $line: String) {
    ovhAgentConnect(number: $number, line: $line) {
      id
      status
    }
  }
`;

export const mutationOVHAgentDisconnect = gql`
  mutation ovhAgentDisconnect($number: String!, $line: String) {
    ovhAgentDisconnect(number: $number, line: $line) {
      id
      status
    }
  }
`;
