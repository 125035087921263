import gql from 'graphql-tag';

export const GetUser = gql`
  query userCurrent {
    userCurrent {
      id
      firstname
      lastname
      email
      type
      phone
      intercomHmac
      zelolines {
        id
        name
        status
      }
    }
  }
`;
