import { useState } from 'react';

export const useToggleSection = (initialState = true) => {
  const [isExpand, setExpand] = useState(initialState);

  const toggleExpand = () => {
    setExpand(!isExpand);
  };

  return [isExpand, toggleExpand];
};
