import React from 'react';
import classNames from 'classnames';
import { useMutation } from '@apollo/react-hooks';

import OVHLine from './OVHLine';
import { mutationOVHAgentConnect, mutationOVHAgentDisconnect } from './actions';

const Zelotel = ({ userCurrentPhoneNumber, zelolines }) => {
  const [connectAgent, connectAgentResult] = useMutation(mutationOVHAgentConnect);
  const [disconnectAgent, disconnectAgentResult] = useMutation(mutationOVHAgentDisconnect);
  const globalStatus =
    new Set(zelolines.map(info => info.status)).size > 1 ? 'partial' : zelolines[0].status;

  return (
    <div className="navbar-item has-dropdown is-hoverable">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="navbar-link">
        Zelotel
        <span
          className={classNames('icon', {
            'has-text-success': globalStatus === 'available',
            'has-text-danger': globalStatus === 'offline',
            'has-text-warning': globalStatus === 'partial',
          })}
        >
          <i className="material-icons is-small">fiber_manual_record</i>
        </span>
      </a>
      <div className="navbar-dropdown is-right">
        <>
          <ul>
            {zelolines.map(({ status, id, name }) => (
              <OVHLine
                mutations={{
                  connectAgent,
                  connectAgentResult,
                  disconnectAgent,
                  disconnectAgentResult,
                }}
                status={status}
                id={id}
                name={name}
                userCurrentPhoneNumber={userCurrentPhoneNumber}
                key={id}
              />
            ))}
          </ul>
          <hr className="navbar-divider" />
          <div className="navbar-item level">
            Toutes lignes
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={classNames('button is-small level-right', {
                'is-loading': disconnectAgentResult.loading || connectAgentResult.loading,
                'is-danger': globalStatus === 'available' || globalStatus === 'partial',
                'is-success': globalStatus === 'offline',
              })}
              onClick={() => {
                (globalStatus === 'available' || globalStatus === 'partial'
                  ? disconnectAgent
                  : connectAgent)({
                  variables: {
                    number: userCurrentPhoneNumber,
                  },
                });
              }}
            >
              <span className="icon">
                <i className="material-icons is-small">
                  {globalStatus === 'offline' ? 'local_phone' : 'phone_missed'}
                </i>
              </span>
            </a>
          </div>
        </>
      </div>
    </div>
  );
};

export default Zelotel;
