import React from 'react';

import './Public.css';
import Logo from 'images/logo.svg';

const Public = ({ children }) => (
  <>
    <header className="has-background-primary has-text-centered header">
      <img src={Logo} alt="Zeloce Logo" width="150" />
    </header>
    <main>{children}</main>
  </>
);

export default Public;
