export const importStatuses = {
  parsing_started: 'En cours',
  parsing_failure: 'Echoué',
  parsing_no_accepts: 'Pas de courses',
  parsing_no_accepts_with_failure: 'Pas de courses avec erreurs',
  parsing_ended: 'Terminé',
  parsing_ended_with_failure: 'Terminé avec erreurs',
  optimization_started: 'Optimisation en cours',
  optimization_ended: 'Optimisation terminée',
  optimization_failed: 'Optimisation échouée',
};
