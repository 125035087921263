import React from 'react';
import ReactDOM from 'react-dom';
import { Settings } from 'luxon';
import ActionCable from 'actioncable';
import * as Sentry from '@sentry/browser';
import { onError } from 'apollo-link-error';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { ApolloProvider } from '@apollo/react-hooks';
import { ActionCableLink } from 'graphql-ruby-client';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import './index.scss';
import { config } from 'utils';
import App from './Containers/App';
import { unregister } from './registerServiceWorker';
import introspectionQueryResultData from './fragmentTypes.json';

Settings.defaultLocale = 'fr';

Sentry.init({
  dsn: config.sentry,
  environment: config.env,
});

const authLink = setContext((request, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      'X-Access-Token': token || '',
    },
  };
});

const hasSubscriptionOperation = ({ query: { definitions } }) =>
  definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
  );

const wsLink = new ActionCableLink({
  cable: ActionCable.createConsumer(`${config.ws}/graphql`),
  connectionParams: {
    authToken: localStorage.getItem('token') || '',
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path, extensions }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code : ${
              extensions ? extensions.code : extensions
            }`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    authLink,
    split(
      hasSubscriptionOperation,
      wsLink,
      createUploadLink({
        uri: `${config.api}/graphql`,
      }),
    ),
  ]),
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
  }),
});

const ApolloApp = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

ReactDOM.render(<ApolloApp />, document.getElementById('root'));
unregister();
