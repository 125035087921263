export const missionStates = {
  aborted: { label: 'Annulée', class: 'Booking-aborted', step: 7 },
  assigned: { label: 'Assignée', class: 'Booking-assigned', step: 1 },
  begun: { label: 'Approche', class: 'Booking-begun', step: 2 },
  delivered: { label: 'Déchargement', class: 'Booking-delivered', step: 5 },
  loaded: { label: 'Livraison', class: 'Booking-loaded', step: 4 },
  picked_up: { label: 'Chargement', class: 'Booking-picked_up', step: 3 },
  unloaded: { label: 'Terminée', class: 'Booking-unloaded', step: 6 },
  waiting: { label: 'Attente', class: 'Booking-waiting', step: 0 },
  canceled: { label: 'Annulée' },
};

export const taskStates = {
  load: 'Chargement',
  unload: 'Déchargement',
};

export const getMissionType = (store, referenceBooking) =>
  !Boolean(store) ? 'freefloating' : Boolean(referenceBooking) ? 'return' : 'delivery';
