import React from 'react';

const FailedRequest = ({ refetch, hideText = false }) => (
  <section>
    {!hideText && <p>Un probleme est survenu veuillez rafraichir dans quelques secondes!</p>}
    <button className="button is-info" onClick={() => refetch()}>
      Réessayer
    </button>
  </section>
);

export default FailedRequest;
