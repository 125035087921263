import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import NotFound from './NotFound';
import Loader from 'Components/Loader';
import { isAuthenticated } from 'utils';
import Public from 'Components/Layouts/Public';
import Private from 'Components/Layouts/Private';
import ErrorBoundary from 'Components/ErrorBoundary';

const Login = lazy(() => import('./Login'));
const Import = lazy(() => import('./Import'));
const Search = lazy(() => import('./Search'));
const ImportView = lazy(() => import('./ImportView'));
const BookingView = lazy(() => import('./BookingView'));
const MissionsList = lazy(() => import('./MissionsList'));
const BookingCreate = lazy(() => import('./Booking/Create'));
const HypertrackDashboard = lazy(() => import('./Hypertrack'));
const DeliveryRoundUpdate = lazy(() => import('././DeliveryRound/Edit'));
const DeliveryRoundCreate = lazy(() => import('./DeliveryRound/Create'));

const App = () => (
  <Router>
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PrivateRoute exact path="/" component={MissionsList} />
          <PrivateRoute exact path="/import" component={Import} />
          <PrivateRoute exact path="/import/:jobId" component={ImportView} />
          <PrivateRoute exact path="/booking/create" component={BookingCreate} />
          <PrivateRoute exact path="/booking/:bookingId/edit" component={BookingView} />
          <PrivateRoute exact path="/delivery-round/new" component={DeliveryRoundCreate} />
          <PrivateRoute exact path="/delivery-round/:id/edit" component={DeliveryRoundUpdate} />
          <PrivateRoute exact path="/tracking" component={HypertrackDashboard} />
          <PrivateRoute exact path="/search" component={Search} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  </Router>
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Redirect to="/" />
      ) : (
        <Public>
          <Component {...props} />
        </Public>
      )
    }
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return isAuthenticated() ? (
        <Private>
          <Component {...props} />
        </Private>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default App;
