import { client } from 'index';

export const isAuthenticated = () => {
  return localStorage.getItem('token');
};

export const signOut = () => {
  client.resetStore();
  localStorage.clear();
};

export const setSession = data => {
  localStorage.setItem('token', data.authenticateLogin.authenticationToken);
};
