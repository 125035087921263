import gql from 'graphql-tag';

export const GetStores = gql`
  query Stores {
    stores(enabled: true) {
      nodes {
        id
        name
        closingHour
        openingHour
        timeSlotLength
        enableBookingDelivery
        enableBookingReturn
        enableBookingExchange
        enableBookingTransfer
        enableBookingStairs
        enableBookingValue
        enableBookingNbPackages
        location {
          id
          city
          postalCode
          latitude
          longitude
        }
      }
    }
  }
`;
