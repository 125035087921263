import { mapValues, get } from 'lodash';

export const filterHelper = (data, filters, filtersKey) => {
  const filtersValues = mapValues(filters, filter => {
    if (!filter) return [];

    return Array.isArray(filter) ? filter.map(item => item.value) : filter.value;
  });

  return data.filter(element =>
    Object.keys(filtersValues).every(key => {
      if (filtersValues[key].length === 0) return true;

      return Array.isArray(filtersValues[key])
        ? filtersValues[key].includes(get(element, filtersKey[key]))
        : filtersValues[key] === get(element, filtersKey[key]);
    }),
  );
};
