/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import classNames from 'classnames';
import * as Sentry from '@sentry/browser';
import { useQuery } from '@apollo/react-hooks';
import { NavLink, withRouter, Redirect } from 'react-router-dom';

import Logo from 'images/logo.svg';
import { GetUser } from 'resources';
import Loader from 'Components/Loader';
import Zelotel from 'Components/Zelotel';
import FailedRequest from 'Components/FailedRequest';
import { signOut, config, useToggleSection } from 'utils';
import IntercomComponent from 'Components/IntercomComponent';

const ADMIN_ROUTES = ['/tracking'];

const Private = ({ children, history, match }) => {
  const [isExpand, toggleExpand] = useToggleSection(false);
  const { loading, error, data, refetch } = useQuery(GetUser, {
    onCompleted: data => {
      const {
        userCurrent: { email, id, firstname, lastname },
      } = data;

      Sentry.configureScope(scope => {
        scope.setUser({ email, id, username: `${lastname || ''} ${firstname || ''}` });
      });
    },
  });

  if (loading) return <Loader />;
  if (error) return <FailedRequest refetch={refetch} />;

  if (ADMIN_ROUTES.includes(match.path) && data.userCurrent.type !== 'Admin') {
    return <Redirect to="/" />;
  }

  return (
    <>
      <header>
        <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <NavLink className="navbar-item" to="/" exact>
              <img src={Logo} alt="Zeloce" width="112" height="28" />
            </NavLink>
            <a
              role="button"
              className={classNames('navbar-burger', { 'is-active': isExpand })}
              aria-label="menu"
              aria-expanded="false"
              data-target="navigation"
              onClick={toggleExpand}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div id="navigation" className={classNames('navbar-menu', { 'is-active': isExpand })}>
            <div className="navbar-start">
              <NavLink activeClassName="is-active" className="navbar-item" exact to="/">
                Tableau de bord
              </NavLink>
              <NavLink activeClassName="is-active" className="navbar-item" exact to="/search">
                Recherche
              </NavLink>
              {data.userCurrent.type === 'Admin' && (
                <NavLink activeClassName="is-active" className="navbar-item" exact to="/tracking">
                  Tracking
                </NavLink>
              )}
            </div>
            <div className="navbar-end">
              {data.userCurrent.zelolines.length > 0 && (
                <Zelotel
                  zelolines={data.userCurrent.zelolines}
                  userCurrentPhoneNumber={data.userCurrent.phone}
                />
              )}
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">
                  {data.userCurrent.firstname}&nbsp;{data.userCurrent.lastname}
                </a>
                <div className="navbar-dropdown  is-right">
                  <a
                    className="navbar-item"
                    href={`${config.api}/admin`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Zeloce.com
                  </a>
                  <IntercomComponent userCurrent={data.userCurrent} />
                  <hr className="navbar-divider" />
                  <a
                    className="navbar-item"
                    onClick={() => {
                      signOut();
                      if (window.Intercom) {
                        window.Intercom('shutdown');
                      }
                      history.push('/');
                    }}
                  >
                    Déconnexion
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <main>{children}</main>
    </>
  );
};

export default withRouter(Private);
