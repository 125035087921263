import React from 'react';

import Layout from 'Components/Layouts/Private';
import NotFoundImage from 'images/not-found.svg';

const NotFound = () => (
  <Layout>
    <main className="section">
      <div className="container is-fullhd is-fluid">
        <div className="box has-text-centered">
          <h1 className="title is-1 is-size-3-mobile">Cette page n'existe pas!</h1>
          <img src={NotFoundImage} alt="Not found" width="50%" />
        </div>
      </div>
    </main>
  </Layout>
);

export default NotFound;
