export const FAMILIES = {
  ETAIEMENT: 'Etaiement',
  COFFRAGE: 'Coffrage',
  OUTILS_SPECIFIQUES: 'Outils spécifiques',
  SECURITE: 'Sécurité',
  ENVIRONNEMENT: 'Environnement',
};

export const EQUIPMENT_TYPES = {
  ETRESILLONS_ETAIS_STANDARDS: 'ETRESILLONS - ETAIS STANDARDS',
  ETAIS_TIRANT_POUSSANT: 'ETAIS TIRANT POUSSANT',
  ETAIS_FORTE_CHARGE: 'ETAIS FORTE CHARGE',
  ACCESSOIRES_ETAIS: 'ACCESSOIRES ETAIS + TREPIEDS',
  POUTRELLE_BOIS: 'POUTRELLE BOIS',
  POUTRELLE_ALU: 'POUTRELLE ALU',
  TOUR_ETAIEMENT_ACIER_MDS: 'TOUR ETAIEMENT ACIER MDS',
  TOUR_ETAIEMENT_ALU_MDS: 'TOUR ETAIEMENT ALU MDS',
  ACCESSOIRES_TOUR_ETAIEMENT_MDS: 'ACCESSOIRES TOUR ETAIEMENT MDS',
  HORS_ACCESSOIRES: 'HORS ACCESSOIRES - BANCHES HUSSOR H12 (mètre)',
  ACCESSOIRES_HUSSOR: 'ACCESSOIRES HUSSOR',
  BANCHE_OUTINORD_EVO: 'BANCHE OUTINORD EVO III & IV',
  ACCESSOIRES_OUTINORD: 'ACCESSOIRES OUTINORD',
  MANUPORTABLES_PERI: 'MANUPORTABLES PERI',
  MANUPORTABLES_STANDARD_ALU: 'MANUPORTABLES STANDARD ALU',
  COFFRAGES_DIVERS: 'COFFRAGES DIVERS',
  GARDE_CORPS: 'GARDE CORPS',
  BARRIERE_TAMMET: 'BARRIERE TYPE "TAMMET" COULEUR JAUNE',
  ACCESSOIRES_TAMMET: 'ACCESSOIRES TYPE "TAMMET"',
  QUAI_DE_DECHARGEMENT: 'QUAI DE DECHARGEMENT',
  GARDE_CORPS_PREFA_HORIZONTALE: 'GARDE CORPS POUR PREFA HORIZONTALE',
  ESCALIERS_ET_DIVERS: 'ESCALIERS + DIVERS',
  ESCALIB: 'ESCALIB',
  PASSERELLES_SCS: 'PASSERELLES SCS (HORS ACCESSOIRES)',
  ACCESSOIRES_PASSERELLE_SCS: 'ACCESSOIRES PASSERELLE SCS',
  PASSERELLE_P3D: 'PASSERELLE P3D',
  CONSOLE_AUTO_COINCANTE: 'CONSOLE AUTO COINCANTE POUR PODIUM',
  RECETTE_A_MATERIAUX: 'RECETTE A MATERIAUX ',
  ENVIRONNEMENT: 'ENVIRONNEMENT',
  CUVE_A_FIOUL: 'CUVE A FIOUL',
  MATERIEL_DE_TOPOGRAPHIE: 'MATERIEL DE TOPOGRAPHIE',
};

export const equipmentNomenclature = {
  validForPrefix: ['spie_'],
  addLabel: 'Ajouter...',
  endl: '\n',
  header: 'Équipement nécessaire :',
  items: [
    {
      family: 'ETAIEMENT',
      type: 'ETRESILLONS_ETAIS_STANDARDS',
      id: 'etresillons-0.6x0.8',
      label: 'ETRESILLONS - 0,60 X 0,80',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETRESILLONS_ETAIS_STANDARDS',
      id: 'etresillons-0.8x1.2',
      label: 'ETRESILLONS - 0,80 X 1,20',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETRESILLONS_ETAIS_STANDARDS',
      id: 'etresillons-1.1x2',
      label: 'ETRESILLONS - 1,10 X 2,00',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETRESILLONS_ETAIS_STANDARDS',
      id: 'etai-diam-57-1.7x2.9',
      label: 'ETAI DIAM 57 - 1,70 X 2,90',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETRESILLONS_ETAIS_STANDARDS',
      id: 'etai-diam-57-2.3x3.8',
      label: 'ETAI DIAM 57 - 2,30 X 3,80',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETRESILLONS_ETAIS_STANDARDS',
      id: 'etai-diam-76-2.25x4.05',
      label: 'ETAI DIAM 76 - 2,25 X 4,05',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETRESILLONS_ETAIS_STANDARDS',
      id: 'etai-diam-76-2.4x4.1',
      label: 'ETAI DIAM 76 - 2,40 X 4,10',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETRESILLONS_ETAIS_STANDARDS',
      id: 'etai-diam-76-3.1x5.5',
      label: 'ETAI DIAM 76 - 3,10 X 5,50',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etai-tirant-poussant-diam-57-1x1.6',
      label: 'ETAI TIRANT POUSSANT DIAMETRE 57 - 1,00 X 1,60',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etai-tirant-poussant-diam-57-1.7x2.9',
      label: 'ETAI TIRANT POUSSANT DIAMETRE 57 - 1,70 X 2,90',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etai-tirant-poussant-diam-57-2x3.4',
      label: 'ETAI TIRANT POUSSANT DIAMETRE 57 - 2,00 X 3,40',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etai-tirant-poussant-diam-57-2.4x3.9',
      label: 'ETAI TIRANT POUSSANT DIAMETRE 57 - 2,40 X 3,90',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etai-tirant-poussant-diam-76-2.4x4.1',
      label: 'ETAI TIRANT POUSSANT DIAMETRE 76 - 2,40 X 4,10',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etai-tirant-poussant-diam-76-3.1x5.5',
      label: 'ETAI TIRANT POUSSANT DIAMETRE 76 - 3,10 X 5,50',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etai-tirant-poussant-diam-76-6.2x8.1',
      label: 'ETAI TIRANT POUSSANT DIAMETRE 76 - 6,20 X 8,10',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etais-multiprop-mp250',
      label: 'etais multiprop mp250',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etais-multiprop-mp350',
      label: 'etais multiprop mp350',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etais-multiprop-mp480',
      label: 'etais multiprop mp480',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_TIRANT_POUSSANT',
      id: 'etais-multiprop-mp625',
      label: 'etais multiprop mp625',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'etais-hp-21-120x150',
      label: 'etais hp 21 - 120 x 150',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'etais-hp-21-170x200',
      label: 'etais hp 21 - 170 x 200',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'etais-hp-21-220x250',
      label: 'etais hp 21 - 220 x 250',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'etais-hp-21-270x300',
      label: 'etais hp 21 - 270 x 300',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'etais-hp-21-320x350',
      label: 'etais hp 21 - 320 x 350',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'etais-hp-21-395x425',
      label: 'etais hp 21 - 395 x 425',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'sl-40-150x180',
      label: 'etais hp 21 - 150 x 180',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'sl-40-175x205',
      label: 'etais hp 21 - 175 x 205',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'sl-40-225x255',
      label: 'etais hp 21 - 225 x 255',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'sl-40-275x305',
      label: 'etais hp 21 - 275 x 305',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'sl-40-325x355',
      label: 'etais hp 21 - 325 x 355',
    },
    {
      family: 'ETAIEMENT',
      type: 'ETAIS_FORTE_CHARGE',
      id: 'sl-40-400x430',
      label: 'etais hp 21 - 400 x 430',
    },
    { family: 'ETAIEMENT', type: 'ACCESSOIRES_ETAIS', id: 'panier-etais', label: 'panier a etais' },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_ETAIS',
      id: 'panier-manutention',
      label: 'panier de manutention',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_ETAIS',
      id: 'panier-stock-grillage',
      label: 'panier stock grillage',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_ETAIS',
      id: 'trepied-a-etais',
      label: 'trepied a etais',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_ETAIS',
      id: 'trepied-a-etais-repliable',
      label: 'TREPIED REPLIABLE AUTOBLOQUANT ETAI 101/B',
    },
    {
      family: 'ETAIEMENT',
      type: 'POUTRELLE_BOIS',
      id: 'poutrelle-bois-h20-l2.45',
      label: 'POUTRELLE BOIS - H = 20 - L = 2,45',
    },
    {
      family: 'ETAIEMENT',
      type: 'POUTRELLE_BOIS',
      id: 'poutrelle-bois-h20-l2.9',
      label: 'POUTRELLE BOIS - H = 20 - L = 2,90',
    },
    {
      family: 'ETAIEMENT',
      type: 'POUTRELLE_BOIS',
      id: 'poutrelle-bois-h20-l3.6',
      label: 'POUTRELLE BOIS - H = 20 - L = 3,60',
    },
    {
      family: 'ETAIEMENT',
      type: 'POUTRELLE_BOIS',
      id: 'poutrelle-bois-h20-l3.9',
      label: 'POUTRELLE BOIS - H = 20 - L = 3,90',
    },
    {
      family: 'ETAIEMENT',
      type: 'POUTRELLE_BOIS',
      id: 'poutrelle-bois-h20-l4.5',
      label: 'POUTRELLE BOIS - H = 20 - L = 4,50',
    },
    { family: 'ETAIEMENT', type: 'POUTRELLE_BOIS', id: 'vente-ml', label: 'vente ml' },
    { family: 'ETAIEMENT', type: 'POUTRELLE_ALU', id: 'poutrelle-alu', label: 'poutrelle alu' },
    {
      family: 'ETAIEMENT',
      type: 'POUTRELLE_ALU',
      id: 'coulisseau-poutre',
      label: 'coulisseau de poutre',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ACIER_MDS',
      id: 'tour-acier-type-c.1.0',
      label: 'TOUR ACIER TYPE C.1.0 - 1,70 X 2,60',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ACIER_MDS',
      id: 'tour-acier-type-c.0.2',
      label: 'TOUR ACIER TYPE C.0.2 - 2,20 X 3,30',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ACIER_MDS',
      id: 'tour-acier-type-c.1.1',
      label: 'TOUR ACIER TYPE C.1.1 - 2,65 X 3,65',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ACIER_MDS',
      id: 'tour-acier-type-c.1.2',
      label: 'TOUR ACIER TYPE C.1.2 - 3,70 X 4,71',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ACIER_MDS',
      id: 'tour-acier-type-c.2.1',
      label: 'TOUR ACIER TYPE C.2.1 - 4,15 X 5,16',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ACIER_MDS',
      id: 'tour-acier-type-c.2.2',
      label: 'TOUR ACIER TYPE C.2.2 - 5,20 X 6,00',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ALU_MDS',
      id: 'tour-alu-type-1.80x2.70',
      label: 'TOUR ALU TYPE - 1,80 X 2,70',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ALU_MDS',
      id: 'tour-alu-type-2.70x3.97',
      label: 'TOUR ALU TYPE - 2,70 X 3,97',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ALU_MDS',
      id: 'tour-alu-type-3.97x5.14',
      label: 'TOUR ALU TYPE - 3,97 X 5,14',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ALU_MDS',
      id: 'tour-alu-type-5.14x6.31',
      label: 'TOUR  ALU TYPE - 5,14 X 6,31',
    },
    {
      family: 'ETAIEMENT',
      type: 'TOUR_ETAIEMENT_ALU_MDS',
      id: 'tour-alu-type-6.31x7.48',
      label: 'TOUR  ALU TYPE - 6,31 X 7,48',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'verin-extremite',
      label: "VERIN D'EXTREMITE PLAQUE IMPERDABLE",
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'verin-fourche',
      label: 'verin fourche',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'cadre-1.50',
      label: 'cadre 1,50 m 6 barreaux',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'demi-cadre-1.05',
      label: 'DEMI CADRE 1,05 M 4 BARREAUX',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'diagonale-cadre-1.50',
      label: "DIAGONALE D'ENTREE POUR CADRE 1,50 M",
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'diagonale-demi-cadre-1.05',
      label: "DIAGONALE D'ENTREE POUR DEMI CADRE 1,05 M",
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'garde-corps-connecteur',
      label: 'GARDE CORPS CONNECTEUR',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'diagonale-1.60m',
      label: 'DIAGONALE 1,60 M',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'diagonale-1.30m',
      label: 'DIAGONALE 1,30 M',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'plateau-a-trappe',
      label: 'PLATEAU A TRAPPE 0,70 X 1,60 M ALU',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'plateau',
      label: 'PLATEAU 0,30 X 1,60 M ACIER',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'connecteur-simple',
      label: 'CONNECTEUR SIMPLE',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'coulisse-courte',
      label: 'COULISSE COURTE',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'cadre-1.50m-3-barreaux',
      label: 'CADRE 1,50 M 3 BARREAUX',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'demi-cadre-1.05-2-barreaux',
      label: 'DEMI CADRE 1,05 2 BARREAUX',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'echelle-1.6m',
      label: 'ECHELLE 1,60 M',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'echelle-2m',
      label: 'ECHELLE 2,00 M',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'plats-30cm',
      label: 'PLATS 30 CM',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'fourche-double-position',
      label: 'FOURCHE DOUBLE POSITION',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'plaque-de-base',
      label: 'PLAQUE DE BASE',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'roulette-et-verin',
      label: 'ROULETTE ET VERIN',
    },
    {
      family: 'ETAIEMENT',
      type: 'ACCESSOIRES_TOUR_ETAIEMENT_MDS',
      id: 'console-pour-tour-etaiement',
      label: 'CONSOLE POUR TOUR ETAIEMENT',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'banche-hussor-2.40x2.80',
      label: 'BANCHE HUSSOR 2.40 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'banche-hussor-1.20x2.80',
      label: 'BANCHE HUSSOR 1.20 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'banche-hussor-0.90x2.80',
      label: 'BANCHE HUSSOR 0.90 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'banche-hussor-0.60x2.80',
      label: 'BANCHE HUSSOR 0.60 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'banche-hussor-0.40x2.80',
      label: 'BANCHE HUSSOR 0.40 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'banche-hussor-0.30x2.80',
      label: 'BANCHE HUSSOR 0.30 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'banche-hussor-0.20x2.80',
      label: 'BANCHE HUSSOR 0.20 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'banche-hussor-0.10x2.80',
      label: 'BANCHE HUSSOR 0.10 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rehausse-hussor-2.40x0.50',
      label: 'REHAUSSE HUSSOR 2.40 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rehausse-hussor-1.20x0.50',
      label: 'REHAUSSE HUSSOR 1.20 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rehausse-hussor-0.90x0.50',
      label: 'REHAUSSE HUSSOR 0.90 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rehausse-hussor-0.60x0.50',
      label: 'REHAUSSE HUSSOR 0.60 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rehausse-hussor-0.40x0.50',
      label: 'REHAUSSE HUSSOR 0.40 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rehausse-hussor-0.30x0.50',
      label: 'REHAUSSE HUSSOR 0.30 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rehausse-hussor-0.20x0.50',
      label: 'REHAUSSE HUSSOR 0.20 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rehausse-hussor-0.10x0.50',
      label: 'REHAUSSE HUSSOR 0.10 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-2.40x1.50',
      label: 'SOUS HAUSSE HUSSOR 2.40 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-1.20x1.50',
      label: 'SOUS HAUSSE HUSSOR 1.20 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.90x1.50',
      label: 'SOUS HAUSSE HUSSOR 0.90 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.60x1.50',
      label: 'SOUS HAUSSE HUSSOR 0.60 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.40x1.50',
      label: 'SOUS HAUSSE HUSSOR 0.40 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.30x1.50',
      label: 'SOUS HAUSSE HUSSOR 0.30 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.20x1.50',
      label: 'SOUS HAUSSE HUSSOR 0.20 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.10x1.50',
      label: 'SOUS HAUSSE HUSSOR 0.10 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-2.40x1.00',
      label: 'SOUS HAUSSE HUSSOR 2.40 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-1.20x1.00',
      label: 'SOUS HAUSSE HUSSOR 1.20 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.90x1.00',
      label: 'SOUS HAUSSE HUSSOR 0.90 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.60x1.00',
      label: 'SOUS HAUSSE HUSSOR 0.60 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.40x1.00',
      label: 'SOUS HAUSSE HUSSOR 0.40 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.30x1.00',
      label: 'SOUS HAUSSE HUSSOR 0.30 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.20x1.00',
      label: 'SOUS HAUSSE HUSSOR 0.20 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sous-hausse-hussor-0.10x1.00',
      label: 'SOUS HAUSSE HUSSOR 0.10 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'angle-int-hussor-1.20+1.20x2.80',
      label: 'ANGLE INT HUSSOR 1.20 + 1.20 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'angle-ext-hussor-0.16+0.16x2.80',
      label: 'ANGLE EXT HUSSOR 0.16 + 0.16 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'angle-ext-hussor-0.18+0.18x2.80',
      label: 'ANGLE EXT HUSSOR 0.18 + 0.18 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'angle-ext-hussor-0.20+0.20x2.80',
      label: 'ANGLE EXT HUSSOR 0.20 + 0.20 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rh-angle-int-hussor-1.20+1.20x0.50',
      label: 'R/H ANGLE INT HUSSOR 1.20 + 1.20 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rh-angle-ext-hussor-0.16+0.16x0.50',
      label: 'R/H ANGLE EXT HUSSOR 0.16 + 0.16 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rh-angle-ext-hussor-0.18+0.18x0.50',
      label: 'R/H ANGLE EXT HUSSOR 0.18 + 0.18 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'rh-angle-ext-hussor-0.20+0.20x0.50',
      label: 'R/H ANGLE EXT HUSSOR 0.20 + 0.20 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sh-angle-int-hussor-1.20+1.20x1.50',
      label: 'S/H ANGLE INT HUSSOR 1.20 + 1.20 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sh-angle-ext-hussor-0.16+0.16x1.50',
      label: 'S/H ANGLE EXT HUSSOR 0.16 + 0.16 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sh-angle-ext-hussor-0.18+0.18x1.50',
      label: 'S/H ANGLE EXT HUSSOR 0.18 + 0.18 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sh-angle-ext-hussor-0.20+0.20x1.50',
      label: 'S/H ANGLE EXT HUSSOR 0.20 + 0.20 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sh-angle-int-hussor-1.20+1.20x1.00',
      label: 'S/H ANGLE INT HUSSOR 1.20 + 1.20 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sh-angle-ext-hussor-0.16+0.16x1.00',
      label: 'S/H ANGLE EXT HUSSOR 0.16 + 0.16 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sh-angle-ext-hussor-0.18+0.18x1.00',
      label: 'S/H ANGLE EXT HUSSOR 0.18 + 0.18 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'HORS_ACCESSOIRES',
      id: 'sh-angle-ext-hussor-0.20+0.20x1.00',
      label: 'S/H ANGLE EXT HUSSOR 0.20 + 0.20 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'etai-stabilite-e1',
      label: 'ETAI DE STABILITE E1 250 A 400 M',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'etai-stabilite-e2',
      label: 'ETAI DE STABILITE E2 4,00 A 5,50 M',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'etai-stabilite-e3',
      label: 'ETAI DE STABILITE E3 5,50 A 7,00 M',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'stab-etai-3.10-5.60m',
      label: 'STAB ETAI 3.10 A 5.60 M',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'stab-etai-4.70-8.20m',
      label: 'STAB ETAI 4.70 A 8.20 M',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'support-stab-etai',
      label: 'SUPPORT STAB ETAI',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'compas-stabileve-hussor',
      label: 'COMPAS STABILEVE HUSSOR',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'panier-compas-stabileve',
      label: 'PANIER COMPAS STABILEVE',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'barette-about-820mm-hussor',
      label: 'BARETTE ABOUT 820 mm HUSSOR',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_HUSSOR',
      id: 'barette-about-620mm-hussor',
      label: 'BARETTE ABOUT 620 mm HUSSOR',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-0.50x3.30',
      label: 'BANCHE OUTINORD 0.50 X 3.30',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-0.40x3.30',
      label: 'BANCHE OUTINORD 0.40 X 3.30',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-0.30x3.30',
      label: 'BANCHE OUTINORD 0.30 X 3.30',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-0.20x3.30',
      label: 'BANCHE OUTINORD 0.20 X 3.30',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-2.5x2.80',
      label: 'BANCHE OUTINORD 2.50 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-1.25x2.80',
      label: 'BANCHE OUTINORD 1.25 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-0.625x2.80',
      label: 'BANCHE OUTINORD 0.625 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-0.50x2.80',
      label: 'BANCHE OUTINORD 0.50 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-0.40x2.80',
      label: 'BANCHE OUTINORD 0.40 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-0.30x2.80',
      label: 'BANCHE OUTINORD 0.30 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'banche-outinord-0.20x2.80',
      label: 'BANCHE OUTINORD 0.20 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-2.5x0.50',
      label: 'REHAUSSE OUTINORD 2.50 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-1.25x0.50',
      label: 'REHAUSSE OUTINORD 1.25 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-0.625x0.50',
      label: 'REHAUSSE OUTINORD 0.625 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-0.50x0.50',
      label: 'REHAUSSE OUTINORD 0.50 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-0.40x0.50',
      label: 'REHAUSSE OUTINORD 0.40 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-0.30x0.50',
      label: 'REHAUSSE OUTINORD 0.30 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-0.20x0.50',
      label: 'REHAUSSE OUTINORD 0.20 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-0.675x0.20',
      label: 'REHAUSSE OUTINORD 0.675 X 0.20',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-1.25x0.20',
      label: 'REHAUSSE OUTINORD 1.25 X 0.20',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rehausse-outinord-2.50x0.20',
      label: 'REHAUSSE OUTINORD 2.50 X 0.20',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-2.5x1.50',
      label: 'SOUS HAUSSE OUTINORD 2.50 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-1.25x1.50',
      label: 'SOUS HAUSSE OUTINORD 1.25 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.625x1.50',
      label: 'SOUS HAUSSE OUTINORD 0.625 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.50x1.50',
      label: 'SOUS HAUSSE OUTINORD 0.50 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.40x1.50',
      label: 'SOUS HAUSSE OUTINORD 0.40 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.30x1.50',
      label: 'SOUS HAUSSE OUTINORD 0.30 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.20x1.50',
      label: 'SOUS HAUSSE OUTINORD 0.20 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-2.5x1.00',
      label: 'SOUS HAUSSE OUTINORD 2.50 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-1.25x1.00',
      label: 'SOUS HAUSSE OUTINORD 1.25 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.625x1.00',
      label: 'SOUS HAUSSE OUTINORD 0.625 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.50x1.00',
      label: 'SOUS HAUSSE OUTINORD 0.50 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.40x1.00',
      label: 'SOUS HAUSSE OUTINORD 0.40 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.30x1.00',
      label: 'SOUS HAUSSE OUTINORD 0.30 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sous-hausse-outinord-0.20x1.00',
      label: 'SOUS HAUSSE OUTINORD 0.20 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'angle-outinord-1.25+1.25x2.80',
      label: 'ANGLE OUTINORD 1.25 + 1.25 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'angle-outinord-0.20+0.20x2.80',
      label: 'ANGLE OUTINORD 0.20 + 0.20 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'angle-outinord-0.18+0.18x2.80',
      label: 'ANGLE OUTINORD 0.18 + 0.18 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'angle-outinord-0.16+0.16x2.80',
      label: 'ANGLE OUTINORD 0.16 + 0.16 X 2.80',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rh-angle-outinord-1.25+1.25x0.50',
      label: 'R/H ANGLE OUTINORD 1.25 + 1.25 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rh-angle-outinord-0.20+0.20x0.50',
      label: 'R/H ANGLE OUTINORD 0.20 + 0.20 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rh-angle-outinord-0.18+0.18x0.50',
      label: 'R/H ANGLE OUTINORD 0.18 + 0.18 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'rh-angle-outinord-0.16+0.16x0.50',
      label: 'R/H ANGLE OUTINORD 0.16 + 0.16 X 0.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sh-angle-outinord-1.25+1.25x1.50',
      label: 'S/H ANGLE OUTINORD 1.25 + 1.25 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sh-angle-outinord-0.20+0.20x1.50',
      label: 'S/H ANGLE OUTINORD 0.20 + 0.20 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sh-angle-outinord-0.18+0.18x1.50',
      label: 'S/H ANGLE OUTINORD 0.18 + 0.18 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sh-angle-outinord-0.16+0.16x1.50',
      label: 'S/H ANGLE OUTINORD 0.16 + 0.16 X 1.50',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sh-angle-outinord-1.25+1.25x1.00',
      label: 'S/H ANGLE OUTINORD 1.25 + 1.25 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sh-angle-outinord-0.20+0.20x1.00',
      label: 'S/H ANGLE OUTINORD 0.20 + 0.20 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sh-angle-outinord-0.18+0.18x1.00',
      label: 'S/H ANGLE OUTINORD 0.18 + 0.18 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'BANCHE_OUTINORD_EVO',
      id: 'sh-angle-outinord-0.16+0.16x1.00',
      label: 'S/H ANGLE OUTINORD 0.16 + 0.16 X 1.00',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'etais-curseur-1',
      label: 'ETAIS A CURSEUR N°1',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'etais-curseur-2',
      label: 'ETAIS A CURSEUR N°2 - 2.10 X 3.80',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'etais-curseur-3',
      label: 'ETAIS A CURSEUR N°3 - 2.45 X 4.40',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'extention-mobile-0.02-0.12x2800',
      label: 'EXTENTION MOBILE 0.02 à 0.12 X 2800',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'extention-mobile-0.10-0.25x2800',
      label: 'EXTENTION MOBILE 0.10 à 0.25 X 2800',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'compas-de-banche-outinord',
      label: 'COMPAS DE BANCHE OUTINORD',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'panier-compas-outinord',
      label: 'PANIER POUR COMPAS',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'barette-about-de-voile-12-25-outinord',
      label: 'BARETTE ABOUT DE VOILE 12/25 OUTINORD',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'barette-about-de-voile-25-55-outinord',
      label: 'BARETTE ABOUT DE VOILE 25/55 OUTINORD',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'lest-beton-1.5t',
      label: 'LEST BETON 1,5T',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'araignee-ancrage-lest',
      label: 'ARAIGNEE ANCRAGE LEST',
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'mannequin-huisserie-magnetique',
      label: "MANNEQUIN D'HUISSERIE MAGNETIQUE",
    },
    {
      family: 'COFFRAGE',
      type: 'ACCESSOIRES_OUTINORD',
      id: 'manuportables-peri',
      label: 'MANUPORTABLES PERI',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'panneau-duo-1.35x0.90',
      label: 'PANNEAU DUO 1.35X0.90',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'panneau-duo-1.35x0.45',
      label: 'PANNEAU DUO 1.35X0.45',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'support-compensation-135-duo',
      label: 'SUPPORT COMPENSATION 135 DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'connecteur-duo',
      label: 'CONNECTEUR DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'fixation-etai-duo',
      label: 'FIXATION ETAI DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'filiere-compensation-duo',
      label: 'FILIERE DE COMPENSATION DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'fixation-tube-duo',
      label: 'FIXATION TUBE DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'ecrou-disque-duo',
      label: 'ECROU DISQUE DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'panneau-duo-1.35x0.30',
      label: 'PANNEAU DUO 1.35 X 0.30',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'support-compensation-60-duo',
      label: 'SUPPORT COMPENSATION 60 DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'ancrage-angle-duo',
      label: 'ANCRAGE ANGLE DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'anneau-levage-duo',
      label: 'ANNEAU LEVAGE DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'outil-universel-duo',
      label: 'OUTIL UNIVERSEL DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'sabot-cadre-duo',
      label: 'SABOT DE CADRE DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'etai-reglage-210-duo',
      label: 'ETAI DE REGLAGE 210 DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'etai-reglage-300-duo',
      label: 'ETAI DE REGLAGE 300 DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'platine-pied-duo',
      label: 'PLATINE DE PIED DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'angle-levage-duo',
      label: 'ANGLE LEVAGE DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'box-80x120-duo',
      label: 'BOX 80 X 120 DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'palette-2.80x1.20-duo',
      label: 'PALETTE 2.80 X 1.20 DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'tige-dw15-1.50ml-duo',
      label: 'TIGE DW15 1.50ML DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'ecrou-3-oreilles-50-duo',
      label: 'ECROU 3 OREILLES 50 DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'plaque-ecrou-orientable-duo',
      label: 'PLAQUE ECROU ORIENTABLE DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'panneau-duo-1.35x0.75',
      label: 'PANNEAU DUO 1.35 X 0.75',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'panneau-duo-1.35x0.60',
      label: 'PANNEAU DUO 1.35 X 0.60',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'panneau-duo-1.35x0.30',
      label: 'PANNEAU DUO 1.35 X 0.30',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'panneau-duo-1.35x0.15',
      label: 'PANNEAU DUO 1.35 X 0.15',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'bouchon-d-20-duo',
      label: 'BOUCHON D 20 DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'support-cp-d-extension-des-15',
      label: 'SUPPORT CP D EXTENSION DES 15',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'connecteur-angle-duo',
      label: "CONNECTEUR D'ANGLE DUO",
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'tete-etai-dfh',
      label: 'TETE ETAI DFH',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'support-mural-duo-82',
      label: 'SUPPORT MURAL DUO 82',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'console-duo-70',
      label: 'CONSOLE DUO 70',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'support-garde-corps-dalle-duo',
      label: 'SUPPORT GARDE CORPS DALLE DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'montant-de-gc-a-tube-gcl',
      label: 'MONTANT DE GC A TUBE GCL',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'barre-de-montage-duo',
      label: 'BARRE DE MONTAGE DUO',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'angle-dc-135x10',
      label: 'ANGLE DC 135 X 10',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'compensation-dwc-135x10',
      label: 'COMPENSATION DWC 135 X 10',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_PERI',
      id: 'corniere-de-manutention',
      label: 'CORNIERE DE MANUTENTION',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_STANDARD_ALU',
      id: 'panneaux-alu-0.75x2.75',
      label: 'PANNEAUX ALU 0.75 X 2.75',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_STANDARD_ALU',
      id: 'panneaux-alu-0.50x2.75',
      label: 'PANNEAUX ALU 0.50 X 2.75',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_STANDARD_ALU',
      id: 'panneaux-alu-0.90x0.90',
      label: 'PANNEAUX ALU 0.90 X 0.90',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_STANDARD_ALU',
      id: 'panneaux-alu-0.90x0.75',
      label: 'PANNEAUX ALU 0.90 X 0.75',
    },
    {
      family: 'COFFRAGE',
      type: 'MANUPORTABLES_STANDARD_ALU',
      id: 'panier-de-manutention-specifique',
      label: 'PANIER DE MANUTENTION SPECIFIQUE',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'moule-de-poutre-mpu-7.20m',
      label: 'MOULE DE POUTRE MPU 7.20M',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'moule-monopoutre-0.90x8.00x1.40',
      label: 'MOULE MONOPOUTRE 0.90 X 8.00 X 1.40',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'moule-bi-poutre-8.00x0.80x0.70',
      label: 'MOULE BI POUTRE 8.00 X 0.80 X 0.70',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'moule-bi-poutre-8.00x1.00x0.70',
      label: 'MOULE BI POUTRE 8.00 X 1.00 X 0.70',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'moule-bi-poutre-long-4.00',
      label: 'MOULE BI POUTRE LONG 4,00',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'about-de-moule',
      label: 'ABOUT DE MOULE',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'moule-quadri-poutre',
      label: 'MOULE QUADRI POUTRE',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'moule-acrotere',
      label: 'MOULE ACROTERE',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'manivelle+tige',
      label: 'MANIVELLE + TIGE',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'table-a-predalles-3.00x7.00',
      label: 'TABLE A PREDALLES 3,00 X 7,00',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'moule-bi-poutre-0.15x1.00',
      label: 'MOULE BI POUTRE 0,15 X 1,00',
    },
    {
      family: 'COFFRAGE',
      type: 'COFFRAGES_DIVERS',
      id: 'barette bi poutre',
      label: 'BARETTE BI POUTRE',
    },
    {
      family: 'OUTILS_SPECIFIQUES',
      type: 'OUTILS_SPECIFIQUES',
      id: 'outils-poteaux',
      label: 'OUTILS POTEAUX',
    },
    {
      family: 'OUTILS_SPECIFIQUES',
      type: 'OUTILS_SPECIFIQUES',
      id: 'outils-de-facade',
      label: 'OUTILS DE FACADE',
    },
    {
      family: 'OUTILS_SPECIFIQUES',
      type: 'OUTILS_SPECIFIQUES',
      id: 'passerelles-de-travail-pour-premurs',
      label: 'PASSERELLES DE TRAVAIL POUR PREMURS',
    },
    {
      family: 'OUTILS_SPECIFIQUES',
      type: 'OUTILS_SPECIFIQUES',
      id: 'outils-de-console-courte',
      label: 'OUTILS DE CONSOLE COURTE',
    },
    {
      family: 'OUTILS_SPECIFIQUES',
      type: 'OUTILS_SPECIFIQUES',
      id: 'rack-a-treillis-soudes',
      label: 'RACK A TREILLIS SOUDES',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS',
      id: 'potelet-0-25-a-tube-1.00ml',
      label: 'POTELET Ø 25 A TUBE 1.00ML',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS',
      id: 'potelet-0-25-a-tube-1.50ml',
      label: 'POTELET Ø 25 A TUBE 1.50ML',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS',
      id: 'potelet-angle-a-tube-1m',
      label: "POTELET D'ANGLE A TUBE 1M",
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS',
      id: 'potelet-angle-a-tube-1.50m',
      label: "POTELET D'ANGLE A TUBE 1,50M",
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS',
      id: 'tube-garde-corps-26x34-l=3.0',
      label: 'TUBE GARDE CORPS 26 X 34 L = 3,0',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS',
      id: 'garde-corps-universel-a-tube',
      label: 'GARDE CORPS UNIVERSEL A TUBE',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS',
      id: 'pince-double-position',
      label: 'PINCE DOUBLE POSITION',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS',
      id: 'pince',
      label: 'PINCE',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS',
      id: 'garde corps trepied pour predalle a tube',
      label: 'GARDE CORPS TREPIED POUR PREDALLE A TUBE',
    },
    {
      family: 'SECURITE',
      type: 'BARRIERE_TAMMET',
      id: 'barriere-mk2-1.30m',
      label: 'BARRIERE MK2 1.30 M',
    },
    {
      family: 'SECURITE',
      type: 'BARRIERE_TAMMET',
      id: 'barriere-mk2-2.00m',
      label: 'BARRIERE MK2 2.00 M',
    },
    {
      family: 'SECURITE',
      type: 'BARRIERE_TAMMET',
      id: 'barriere-mk2-2.30m',
      label: 'BARRIERE MK2 2.30 M',
    },
    {
      family: 'SECURITE',
      type: 'BARRIERE_TAMMET',
      id: 'barriere-mk2-2.60m',
      label: 'BARRIERE MK2 2.60 M',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'potelet-reglable-1.50m',
      label: 'POTELET REGLABLE 1.50 M (glisseur compris)',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'potelet-reversible-1.50m',
      label: 'POTELET REVERSIBLE 1.50 M (glisseur compris)',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'support-de-plinthe',
      label: 'SUPPORT DE PLINTHE',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'glisseur-v1',
      label: 'GLISSEUR V1',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'glisseur-v2-bleu',
      label: 'GLISSEUR V2 BLEU',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'poteau-scandinave',
      label: 'POTEAU SCANDINAVE',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'socle',
      label: 'SOCLE (à fixer au sol)',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'lisse-de-baie-extensible-a-tetons-70x120',
      label: 'LISSE DE BAIE EXTENSIBLE A TETONS 70 X 120',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'lisse-de-baie-extensible-a-tetons-110x170',
      label: 'LISSE DE BAIE EXTENSIBLE A TETONS 110 X 170',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'lisse-de-baie-extensible-a-tetons-140x240',
      label: 'LISSE DE BAIE EXTENSIBLE A TETONS 140 X 240',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'panier-grillage-pour-tube-gc-3.00ml',
      label: 'PANIER GRILLAGE POUR TUBE GC 3.00 ML',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'caisse-grillage-pour-potelet',
      label: 'CAISSE GRILLAGE POUR POTELET',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'panier-manutention',
      label: 'PANIER MANUTENTION',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'caisse-de-manutention',
      label: 'CAISSE DE MANUTENTION',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'protection-ascenseur-0.60',
      label: 'PROTECTION ASCENSEUR 0,60',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'protection-ascenseur-0.75',
      label: 'PROTECTION ASCENSEUR 0,75',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'protection-ascenseur-0.80',
      label: 'PROTECTION ASCENSEUR 0,80',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'protection-ascenseur-1.00',
      label: 'PROTECTION ASCENSEUR 1,00',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'gc-complet-pour-dalle-alveolaire',
      label: 'GC COMPLET POUR DALLE ALVEOLAIRE',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'montant-depart-4.00m-a-bloqueur-renforce',
      label: 'MONTANT DEPART 4.00m A BLOQUEUR RENFORCE',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_TAMMET',
      id: 'console-pour-montant-de-depart',
      label: 'CONSOLE POUR MONTANT DE DEPART',
    },
    {
      family: 'SECURITE',
      type: 'QUAI_DE_DECHARGEMENT',
      id: 'quai-de-dechargement-4m',
      label: 'QUAI DE DECHARGEMENT 4M',
    },
    {
      family: 'SECURITE',
      type: 'QUAI_DE_DECHARGEMENT',
      id: 'quai-de-dechargement-6m',
      label: 'QUAI DE DECHARGEMENT 6M',
    },
    {
      family: 'SECURITE',
      type: 'QUAI_DE_DECHARGEMENT',
      id: 'quai-de-dechargement-12m',
      label: 'QUAI DE DECHARGEMENT 12M',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS_PREFA_HORIZONTALE',
      id: 'garde-corps-ring-predalles-reglables-avec-acces-petit-modele-6.00m',
      label: 'RING PREDALLES REGLABLES AVEC ACCES PETIT MODELE 6.00m',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS_PREFA_HORIZONTALE',
      id: 'garde-corps-ring-predalles-reglables-avec-acces-grand-modele-9.00m',
      label: 'RING PREDALLES REGLABLES AVEC ACCES GRAND MODELE 9.00m',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS_PREFA_HORIZONTALE',
      id: 'garde-corps-dap-reglable-avec-acces-petit-modele',
      label: 'DAP REGLABLE AVEC ACCES PETIT MODELE',
    },
    {
      family: 'SECURITE',
      type: 'GARDE_CORPS_PREFA_HORIZONTALE',
      id: 'garde-corps-dap-reglable-avec-acces-grand-modele',
      label: 'DAP REGLABLE AVEC ACCES GRAND MODELE (5,00 à 11,00 m)',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIERS_ET_DIVERS',
      id: 'gazelle-2-marches',
      label: 'GAZELLE 2 MARCHES',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIERS_ET_DIVERS',
      id: 'gazelle-3-marches',
      label: 'GAZELLE 3 MARCHES',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIERS_ET_DIVERS',
      id: 'gazelle-4-marches',
      label: 'GAZELLE 4 MARCHES',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIERS_ET_DIVERS',
      id: 'gazelle-5-marches',
      label: 'GAZELLE 5 MARCHES',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIERS_ET_DIVERS',
      id: 'gazelle-6-marches',
      label: 'GAZELLE 6 MARCHES',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIERS_ET_DIVERS',
      id: 'gazelle-special-banche-6-marches',
      label: 'GAZELLE SPECIAL BANCHE 6 MARCHES',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIERS_ET_DIVERS',
      id: 'gazelle-7-marches',
      label: 'GAZELLE 7 MARCHES',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIERS_ET_DIVERS',
      id: 'gazelle-special-banche-7-marches',
      label: 'GAZELLE SPECIAL BANCHE 7 MARCHES',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIB',
      id: 'embase',
      label: 'EMBASE',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIB',
      id: 'module',
      label: 'MODULE',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIB',
      id: 'garde-corps',
      label: 'GARDE CORPS',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIB',
      id: 'escalier-fond-de-fouille-12-marches',
      label: 'ESCALIER FOND DE FOUILLE 12 marches',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIB',
      id: 'escalier-fond-de-fouille-15-marches',
      label: 'ESCALIER FOND DE FOUILLE 15 marches',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIB',
      id: 'escalier-fond-de-fouille-18-marches',
      label: 'ESCALIER FOND DE FOUILLE 18 marches',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIB',
      id: 'escalier-fond-de-fouille-21-marches',
      label: 'ESCALIER FOND DE FOUILLE 21 marches',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIB',
      id: 'support-étai-de-renfort-escalier-sup-15-marches',
      label: 'SUPPORT ÉTAI DE RENFORT ESCALIER SUP. 15 marches',
    },
    {
      family: 'SECURITE',
      type: 'ESCALIB',
      id: 'passage-pietons-4ml',
      label: 'PASSAGE PIETONS 4 ML',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'etude-passerelle',
      label: 'ETUDE PASSERELLE',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'passerelle-scs-6.00m-a',
      label: 'PASSERELLE SCS 6.00 M A',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'passerelle-scs-4.80m-a',
      label: 'PASSERELLE SCS 4.80 M B',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'passerelle-scs-4.20m-c',
      label: 'PASSERELLE SCS 4.20 M C',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'passerelle-scs-3.60m-d',
      label: 'PASSERELLE SCS 3.60 M D',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'passerelle-scs-3.00m-e',
      label: 'PASSERELLE SCS 3.00 M E',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'passerelle-scs-2.40m-f',
      label: 'PASSERELLE SCS 2.40 M F',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'passerelle-scs-1.80m-g',
      label: 'PASSERELLE SCS 1.80 M G',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'passerelle-scs-1.20m-h',
      label: 'PASSERELLE SCS 1.20 M H',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'angle-reversible-scs-i',
      label: 'ANGLE REVERSIBLE SCS I',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'angle-de-passerelle-exterieur-j',
      label: 'ANGLE DE PASSERELLE EXTERIEUR J',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'ml-de-passerelle-non-pris',
      label: 'ML DE PASSERELLE NON PRIS',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'extension-passerelle',
      label: 'EXTENSION PASSERELLE',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'extension-arriere',
      label: 'EXTENSION ARRIERE',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLES_SCS',
      id: 'extension-biaise',
      label: 'EXTENSION BIAISE',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_PASSERELLE_SCS',
      id: 'rallonge-de-pied-rm-158',
      label: 'RALLONGE DE PIED -RM 158',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_PASSERELLE_SCS',
      id: 'rallonge-de-pied-rm-58',
      label: 'RALLONGE DE PIED -RM 58',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_PASSERELLE_SCS',
      id: 'pied-de-baie-scs',
      label: 'PIED DE BAIE SCS',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_PASSERELLE_SCS',
      id: 'attache-volante-scs',
      label: 'ATTACHE VOLANTE SCS',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_PASSERELLE_SCS',
      id: 'attaches-sur-dalle-600',
      label: 'ATTACHES SUR DALLE 600',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_PASSERELLE_SCS',
      id: 'attaches-sur-dalle-1000',
      label: 'ATTACHES SUR DALLE 1000',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_PASSERELLE_SCS',
      id: 'attaches-sous-dalle-600',
      label: 'ATTACHES SOUS DALLE 600',
    },
    {
      family: 'SECURITE',
      type: 'ACCESSOIRES_PASSERELLE_SCS',
      id: 'attaches-sous-dalle-1000',
      label: 'ATTACHES SOUS DALLE 1000',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLE_P3D',
      id: 'p3d-module-m1-1.00x2.20',
      label: 'P3D MODULE M1 : 1,00 X 2,20',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLE_P3D',
      id: 'p3d-module-m1-1.00x2.20',
      label: 'P3D MODULE M1 : 1,00 X 2,20',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLE_P3D',
      id: 'p3d-module-m1-3.70x6.50',
      label: 'P3D MODULE M1 : 3,70 X 6,50',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLE_P3D',
      id: 'extension-arriere-m1',
      label: 'EXTENSION ARRIERE M1',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLE_P3D',
      id: 'extension-arriere-m2',
      label: 'EXTENSION ARRIERE M2',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLE_P3D',
      id: 'extension-arriere-m3',
      label: 'EXTENSION ARRIERE M3',
    },
    {
      family: 'SECURITE',
      type: 'PASSERELLE_P3D',
      id: 'attache-avri',
      label: 'ATTACHE AVRI',
    },
    {
      family: 'SECURITE',
      type: 'CONSOLE_AUTO_COINCANTE',
      id: 'petite',
      label: 'PETITE',
    },
    {
      family: 'SECURITE',
      type: 'CONSOLE_AUTO_COINCANTE',
      id: 'grande',
      label: 'GRANDE',
    },
    {
      family: 'SECURITE',
      type: 'CONSOLE_AUTO_COINCANTE',
      id: 'clap-rochet',
      label: 'CLAP ROCHET',
    },
    {
      family: 'SECURITE',
      type: 'CONSOLE_AUTO_COINCANTE',
      id: 'clap-podium',
      label: 'CLAP PODIUM',
    },
    {
      family: 'SECURITE',
      type: 'CONSOLE_AUTO_COINCANTE',
      id: 'podium-sur-rochet',
      label: 'PODIUM SUR ROCHET',
    },
    {
      family: 'SECURITE',
      type: 'CONSOLE_AUTO_COINCANTE',
      id: 'podium-sur-clap',
      label: 'PODIUM SUR CLAP',
    },
    {
      family: 'SECURITE',
      type: 'RECETTE_A_MATERIAUX',
      id: 'recette-a-materiaux-2500kg',
      label: 'RECETTE A MATERIAUX 2500 Kg',
    },
    {
      family: 'SECURITE',
      type: 'RECETTE_A_MATERIAUX',
      id: 'attache-volante-recette-a-materiaux',
      label: 'ATTACHE VOLANTE RECETTE A MATERIAUX',
    },
    {
      family: 'SECURITE',
      type: 'RECETTE_A_MATERIAUX',
      id: 'palonnier-autostable',
      label: 'PALONNIER AUTOSTABLE',
    },
    {
      family: 'SECURITE',
      type: 'RECETTE_A_MATERIAUX',
      id: 'palonnier-de-relevage',
      label: 'PALONNIER DE RELEVAGE',
    },
    {
      family: 'SECURITE',
      type: 'RECETTE_A_MATERIAUX',
      id: 'palonnier-16m-8t',
      label: 'PALONNIER 16 M/8T',
    },
    {
      family: 'SECURITE',
      type: 'RECETTE_A_MATERIAUX',
      id: 'palonnier-ecarteur',
      label: 'PALONNIER ECARTEUR',
    },
    {
      family: 'SECURITE',
      type: 'RECETTE_A_MATERIAUX',
      id: 'palonnier-pour-p3d',
      label: 'PALONNIER POUR P3D',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'bac-de-retention-soluetanche',
      label: 'BAC DE RETENTION SOLUETANCHE',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'support-cuve-1000l-2-futs-bac-retention',
      label: 'SUPPORT CUVE 1000L/2 FUTS BAC RETENTION',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'support-bigbag',
      label: 'SUPPORT BIGBAG',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'podium-de-nettoyage-benne-a-beton-avec-pompe',
      label: 'PODIUM DE NETTOYAGE BENNE A BETON AVEC POMPE',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'bac-de-decantation-avec-podium-secanet',
      label: 'BAC DE DECANTATION AVEC PODIUM (SECANET)',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'betonnet-gc',
      label: 'BETONNET GC',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'debourdeur',
      label: 'DEBOURDEUR',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'station-ecodo',
      label: 'STATION ECODO',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'centrale-beton-1000l',
      label: 'CENTRALE BETON 1000 L',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'centrale-beton-1500l',
      label: 'CENTRALE BETON 1500 L',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'tremie agregat',
      label: 'TREMIE-AGREGAT',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'ENVIRONNEMENT',
      id: 'tremie-attente-7m3',
      label: "TREMIE D'ATTENTE 7 M3",
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'CUVE_A_FIOUL',
      id: 'cuve-a-fioul-double-paroie-960l',
      label: 'CUVE A FIOUL DOUBLE PAROIE 960 L',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'MATERIEL_DE_TOPOGRAPHIE',
      id: 'station-hilti-pos18',
      label: 'STATION HILTI - POS 18',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'MATERIEL_DE_TOPOGRAPHIE',
      id: 'niveau',
      label: 'NIVEAU',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'MATERIEL_DE_TOPOGRAPHIE',
      id: 'laser-topcon-leica',
      label: 'LASER TOPCON LEICA',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'MATERIEL_DE_TOPOGRAPHIE',
      id: 'laser-hilti-pr30-hvs',
      label: 'LASER HILTI PR30 HVS',
    },
    {
      family: 'ENVIRONNEMENT',
      type: 'MATERIEL_DE_TOPOGRAPHIE',
      id: 'theodolite',
      label: 'THEODOLITE',
    },
  ],
};
