import { get } from 'lodash';

export const bookingPaymentStates = {
  prepaid: { label: 'Pré-payée', class: 'is-warning' },
  paid: { label: 'Payée', class: 'is-success' },
  none: { label: 'Aucun', class: 'is-danger' },
};

export const bookingErrors = {
  customer_phone_invalid: 'Numéro de téléphone non-valide',
  customer_email_invalid: 'Adresse email non-valide',
  customer_invalid: 'Problème à la création du client',
  store_invalid: "Le magasin n'a pas été créé",
  slot_start_invalid: "L'heure de début du créneau de départ est invalide",
  slot_end_invalid: "L'heure de fin du créneau de départ est invalide",
  slot_invalid: "L'heure de départ est invalide (probablement dans le passé)",
  origin_location_not_found: "L'adresse de départ n'a pu être résolue",
  destination_location_not_found: "L'adresse d'arrivée n'a pu être résolue",
  vehicle_category_invalid: "L'adresse d'arrivée n'a pu être résolue",
  user_not_authorized: "L'utilisateur n'est pas autorisé pour ce magasin",
  route_not_found: "Aucune route n'a été trouvé entre départ et arrivée",
  network_error: "Le serveur de routage n'a pu être joint",
  route_invalid: "Une des adresses n'a pu être validée",
  unknown_error: 'Erreur non répertoriée',
  price_calculation_error: "Aucun tarif n'existe pour cette prestation",
  price_unserved_city_error: "Cette ville n'est pas desservie",
  price_association_error: 'Pas de tarif avec ce véhicule',
  price_distance_error: 'Pas de tarif pour ce véhicule avec cette distance',
  price_error: 'Impossible de trouver un tarif',
  invalid_price: "Aucun tarif n'a pu être trouvé pour cette prestation",
  booking_not_validated: 'Les informations de réservation ne sont pas valide',
  invalid_duplicate_exists: 'Cette course existe déjà',
  booking_mission_code_invalid: 'Impossible de créer un code de réservation',
  booking_not_found: 'Réservation introuvable',
  booking_persist_failure: "Impossible d'enregistrer la réservation",
  invalid_cell: 'Valeur invalide',
  missing_header: 'Colonne manquante',
};

export const BOOKING_ERRORS = {
  priceError: 'Tarif inexistant pour cette course',
  customerPhoneInvalid: 'Le numéro de téléphone est invalide',
  customerEmailInvalid: "L'email est invalide",
  customerInvalid: 'Impossible de créer le client',
  storeInvalid: 'Le magasin est invalide',
  slotStartInvalid: 'Le créneau est invalide',
  slotEndInvalid: 'Le créneau est invalide',
  slotInvalid: 'Le créneau est invalide',
  originLocationNotFound: "L'adresse de départ est invalide",
  destinationLocationNotFound: "L'adresse d'arrivée est invalide",
  vehicleCategoryInvalid: 'Le véhicule est invalide',
  userNotAuthorized: "L'utilisateur n'est pas autorisé pour ce magasin",
  routeNotFound: "Aucune route n'a été trouvé entre départ et arrivée",
  networkError: "Le serveur de routage n'a pu être joint",
  routeInvalid: "Une des adresses n'a pu être validée",
  unknownError: 'Erreur non répertoriée',
  priceCalculationError: "Aucun tarif n'existe pour cette prestation",
  priceUnservedCityError: "Cette ville n'est pas desservie",
  priceAssociationError: 'Pas de tarif avec ce véhicule',
  priceDistanceError: 'Pas de tarif pour ce véhicule avec cette distance',
  invalidPrice: "Aucun tarif n'a pu être trouvé pour cette prestation",
  bookingNotValidated: 'Les informations de réservation ne sont pas valide',
  invalidDuplicateExists: 'Cette course existe déjà',
  bookingMissionCodeInvalid: 'Impossible de créer un code de réservation',
  bookingNotFound: 'Réservation introuvable',
  bookingPersistFailure: "Impossible d'enregistrer la réservation",
};

export const bookingParserErrors = errors =>
  errors.map(error => BOOKING_ERRORS[get(error, 'extensions.code')]);

export const formBookingErrors = errors =>
  errors.reduce(
    (result, error) => {
      const code = get(error, 'extensions.code');
      const message = BOOKING_ERRORS[code] || '';
      switch (code) {
        case 'priceError':
        case 'userNotAuthorized':
        case 'routeNotFound':
        case 'networkError':
        case 'routeInvalid':
        case 'unknownError':
        case 'priceCalculationError':
        case 'priceUnservedCityError':
        case 'priceAssociationError':
        case 'priceDistanceError':
        case 'invalidPrice':
        case 'bookingNotValidated':
        case 'invalidDuplicateExists':
        case 'bookingMissionCodeInvalid':
        case 'bookingPersistFailure':
          return { ...result, global: [...result.global, message] };

        case 'customerEmailInvalid':
          return {
            ...result,
            contact: {
              ...result.contact,
              email: message,
            },
          };

        case 'customerPhoneInvalid':
          return {
            ...result,
            contact: {
              ...result.contact,
              phone: message,
            },
          };

        case 'customerInvalid':
          return {
            ...result,
            contact: message,
          };

        case 'storeInvalid':
          return {
            ...result,
            storeId: message,
          };

        case 'slotStartInvalid':
        case 'slotEndInvalid':
        case 'slotInvalid':
          return {
            ...result,
            time: message,
          };

        case 'originLocationNotFound':
          return {
            ...result,
            origin: message,
          };
        case 'destinationLocationNotFound':
          return {
            ...result,
            destination: message,
          };

        case 'vehicleCategoryInvalid':
          return {
            ...result,
            vehicleCategoryId: message,
          };

        default:
          return result;
      }
    },
    { global: [] },
  );
