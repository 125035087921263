import React from 'react';
import classNames from 'classnames';

const OVHLine = ({
  id,
  mutations: { disconnectAgent, connectAgent, disconnectAgentResult, connectAgentResult },
  name,
  status,
  userCurrentPhoneNumber,
}) => (
  <li className="navbar-item level">
    <span>{name}</span>
    <div className="level-right">
      <span
        className={classNames('icon', {
          'has-text-success': status === 'available',
          'has-text-danger': status === 'offline',
        })}
      >
        <i className="material-icons is-small">fiber_manual_record</i>
      </span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={classNames('button is-small', {
          'is-loading': disconnectAgentResult.loading || connectAgentResult.loading,
          'is-danger': status === 'available',
          'is-success': status === 'offline',
        })}
        onClick={() => {
          (status === 'available' ? disconnectAgent : connectAgent)({
            variables: {
              number: userCurrentPhoneNumber,
              line: id,
            },
          });
        }}
      >
        <span className="icon">
          <i className="material-icons is-small">
            {status === 'available' ? 'phone_missed' : 'local_phone'}
          </i>
        </span>
      </a>
    </div>
  </li>
);

export default OVHLine;
