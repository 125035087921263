import { pullAt } from 'lodash';

export const computeDeliveryRoundSteps = tasks =>
  tasks.reduce((summary, task) => {
    if (
      Boolean(summary[summary.length - 1]) &&
      summary[summary.length - 1].id === task.visit.location.id
    ) {
      const summaryCopy = [...summary];
      const currentVisit = summary[summary.length - 1];
      pullAt(summaryCopy, [summary.length - 1]);

      return [
        ...summaryCopy,
        {
          ...currentVisit,
          tasks: [...currentVisit.tasks, task],
        },
      ];
    } else {
      return [
        ...summary,
        {
          id: task.visit.location.id,
          location: task.visit.location,
          tasks: [task],
        },
      ];
    }
  }, []);
