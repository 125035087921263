import Intercom from 'react-intercom';
import React, { Component, Fragment } from 'react';

import './styles.css';

class IntercomComponent extends Component {
  state = {
    visible: false,
  };

  toIntercomUser = user => ({
    user_id: user.id,
    email: user.email,
    name: `${user.firstname} ${user.lastname}`,
    type: user.type,
    phone: user.phone,
    user_hash: user.intercomHmac,
  });

  toggleIntercom = () => {
    this.setState(
      prevState => ({ visible: !prevState.visible }),
      () => {
        this.state.visible ? window.Intercom('hide') : window.Intercom('show');
      },
    );
  };

  render() {
    return (
      <Fragment>
        <Intercom
          appID="ycjll6dk"
          hide_default_launcher
          {...this.toIntercomUser(this.props.userCurrent)}
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="navbar-item" onClick={this.toggleIntercom}>
          Support
        </a>
      </Fragment>
    );
  }
}

export default IntercomComponent;
