import React from 'react';
import classNames from 'classnames';

import './index.css';

const Loader = ({ white = false }) => {
  return <div className={classNames('Loader', { 'Loader--white': white })} />;
};

export default Loader;
