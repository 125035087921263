import gql from 'graphql-tag';

import { MissionFragment } from './mission';

export const GetBookingQuote = gql`
  query BookingQuote($storeId: Int, $mission: MissionAttributes!) {
    bookingQuote(storeId: $storeId, mission: $mission) {
      priceTotal
      mission {
        distance
        duration
      }
    }
  }
`;

export const MutationBookingCreate = gql`
  mutation BookingCreate(
    $storeId: Int
    $mission: MissionAttributes!
    $customer: CustomerAttributes!
  ) {
    bookingCreate(storeId: $storeId, mission: $mission, customer: $customer) {
      id
      mission {
        ...MissionFragment
      }
    }
  }
  ${MissionFragment}
`;

export const mutationBookingCancel = gql`
  mutation BookingCancel($id: String!, $canceledFor: String!, $canceledType: CanceledTypeEnum!) {
    bookingCancel(id: $id, canceledFor: $canceledFor, canceledType: $canceledType) {
      id
      mission {
        id
        run {
          state
        }
      }
    }
  }
`;

export const mutationBookingReturn = gql`
  mutation BookingReturn($id: String, $storeId: Int, $mission: MissionAttributes!) {
    bookingReturn(id: $id, storeId: $storeId, mission: $mission) {
      id
    }
  }
`;

export const mutationBookingDuplicate = gql`
  mutation BookingDuplicate($id: String, $storeId: Int, $mission: MissionAttributes!) {
    bookingDuplicate(id: $id, storeId: $storeId, mission: $mission) {
      id
    }
  }
`;
